// Import our external dependencies.
import { writable, get } from "svelte/store";
import { urlToData, formatUrl } from "mcgregor-utils/url-utils";

// Make initial reads
const routeData = chopTheHashUp();

// Instantiate the individual stores, elevating their set functions.
export const url = writable(routeData.url);
export const route = writable(routeData.route);
export const route0 = writable(routeData.route0);
export const route1 = writable(routeData.route1);
export const route2 = writable(routeData.route2);
export const route3 = writable(routeData.route3);
export const route4 = writable(routeData.route4);
export const route5 = writable(routeData.route5);
export const route6 = writable(routeData.route6);
export const queryParameters = writable(routeData.queryParameters);

// Setup hash based routing.
window.addEventListener("hashchange", refreshRoute);
window.addEventListener("hashchange", function checkLength() {
  if (window.location.hash.length > 2000) {
    window.console.error(
      "🚨🚨🚨🚨 You have exceeded the maximum URL length of 2000 characters!"
    );
  }
});

// Provide a function to refresh the app's route based on the nav bar.
export function refreshRoute() {
  const routeData = chopTheHashUp();
  route.set(routeData.route);
  route0.set(routeData.route0);
  route1.set(routeData.route1);
  route2.set(routeData.route2);
  route3.set(routeData.route3);
  route4.set(routeData.route4);
  route5.set(routeData.route5);
  route6.set(routeData.route6);
  url.set(routeData.url);
  queryParameters.set(routeData.queryParameters);
}

function chopTheHashUp() {
  return urlToData(window.location.hash, true);
}

// Provide a function to update query parameters programmatically.
export function updateQueryParameters(newQPs = {}, replace = true) {
  const $route = get(route);
  const $queryParameters = get(queryParameters);
  const newState = `#/${formatUrl({ newQPs, $route, $queryParameters })}`;
  if (replace) {
    window.history.replaceState(null, null, newState);
    return refreshRoute();
  }
  window.location = newState;
}
