// Import our external dependencies.
import { translate } from "i18n"; //eslint-disable-line import/no-unresolved

// Turns a float into Minutes:Seconds
export function MSS(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s; // eslint-disable-line
}

// Return loading-spinner HTML
export function loadingSpinnerHTML(className) {
  return `
  <div class="text-center spinner-delayed ${className}">
    <div class="spinner-border" role="status">
      <span class="sr-only">
        ${translate("notifications.loadingDotDotDot")}
      </span>
    </div>
  </div>
  `;
}
