// Include our external dependencies.
import noop from "lodash-es/noop";
import offlineStorage from "store/dist/store.modern.min";
import { writable } from "svelte/store";

// Toolbar
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const active_tool_idx = writable(
  offlineStorage.get("active_tool_idx") || 0
);
export const show_metadata = writable(offlineStorage.get("show_metadata"));

// Reflect toolbar selections into local storage
active_tool_idx.subscribe(($active_tool_idx) => {
  offlineStorage.set("active_tool_idx", $active_tool_idx);
});
show_metadata.subscribe(($show_metadata) => {
  offlineStorage.set("show_metadata", $show_metadata);
});

// Default Color Maps - Can be overwritten by user.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const colorMaps = {
  "2D": [
    {
      name: "2D",
      render: noop,
    },
  ],
  MPR: [
    {
      name: "MPR",
      render: noop,
    },
  ],
  "3D": [
    {
      name: "Clinical 1",
      render(viewer) {
        viewer.show3DClinical1View();
      },
    },
    {
      name: "Clinical 2",
      render(viewer) {
        viewer.show3DClinical2View();
      },
    },
    {
      name: "Clinical 3",
      render(viewer) {
        viewer.show3DClinical3View();
      },
    },
    {
      name: "Clinical 4",
      render(viewer) {
        viewer.show3DClinical4View();
      },
    },
    {
      name: "Sinew 1",
      render(viewer) {
        viewer.show3DSinew1View();
      },
    },
    {
      name: "Sinew 2",
      render(viewer) {
        viewer.show3DSinew2View();
      },
    },
    {
      name: "Sinew 3",
      render(viewer) {
        viewer.show3DSinew3View();
      },
    },
    {
      name: "Sinew 4",
      render(viewer) {
        viewer.show3DSinew4View();
      },
    },
  ],
};

// 2D
export const defaultColorMap2D = writable(
  offlineStorage.get("defaultColorMap2D") || colorMaps["2D"][0].name
);
defaultColorMap2D.subscribe((dcm) =>
  offlineStorage.set("defaultColorMap2D", dcm)
);

// MPR
export const defaultColorMapMPR = writable(
  offlineStorage.get("defaultColorMapMPR") || colorMaps.MPR[0].name
);
defaultColorMapMPR.subscribe((dcm) =>
  offlineStorage.set("defaultColorMapMPR", dcm)
);

// 3D
export const defaultColorMap3D = writable(
  offlineStorage.get("defaultColorMap3D") || colorMaps["3D"][0].name
);
defaultColorMap3D.subscribe((dcm) =>
  offlineStorage.set("defaultColorMap3D", dcm)
);
