<script>
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { active_tool_idx, show_metadata } from "./stores";
  import { showAlert } from "../stores/alerts";
  import { queryParameters, updateQueryParameters } from "../stores/router";
  import AddSeriesModal from "./add-series-modal/add-series-modal.svelte";
  import TutorialModal from "./tutorial-modal.svelte";
  import KbShortcutsModal from "./keyboard-shortcuts-modal.svelte";

  // Component Props
  const tools = [
    {
      name: "luminosity",
      icon: "sun",
      hed: translate("multiviewer.luminosity"),
    },
    {
      name: "rocker",
      icon: "cube",
      hed: translate("multiviewer.rocker"),
    },
  ];

  function handleShareBtnClick() {
    // Use native share dialog if it's supported…
    if ("share" in window.navigator)
      return window.navigator.share({ url: window.location }).catch(() => true);

    // …otherwise, copy the current address to their clipboard and alert them.
    if (
      "clipboard" in window.navigator &&
      window.navigator.clipboard.writeText
    ) {
      window.navigator.clipboard.writeText(window.location);
      showAlert({
        message: translate("multiviewer.url_in_clipboard"),
        type: "secondary",
        autodismiss_ms: 2400,
      });
    }
  }
</script>

<header class="p-2 d-flex">
  <!-- Tool Selector -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->

  <!-- Dropdown Button ( Mobile ) -->
  <div>
    <div class="btn-group mr-2 | d-md-none">
      <button
        type="button"
        class="btn btn-sm btn-primary dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fas fa-fw fa-{tools[$active_tool_idx].icon} mr-1" />
        <span class="d-none d-sm-inline">{tools[$active_tool_idx].hed}</span>
      </button>
      <div class="dropdown-menu">
        {#each tools as { icon, hed }, i (hed)}
          {#if i !== $active_tool_idx}
            <button
              type="button"
              class="dropdown-item pl-2 pr-3"
              on:click={() => ($active_tool_idx = i)}
            >
              <i class="fas fa-fw fa-{icon} mr-1" />
              <span>{hed}</span>
            </button>
          {/if}
        {/each}
      </div>
    </div>

    <!-- Radio Buttons ( Large Screen ) -->
    <div
      class="btn-group btn-group-sm mr-2 | d-none d-md-flex"
      role="group"
      aria-label="First group"
    >
      {#each tools as { icon, hed }, i (hed)}
        <button
          type="button"
          class="btn btn-primary"
          class:active={i === $active_tool_idx}
          on:click={() => ($active_tool_idx = i)}
        >
          <i class="fas fa-fw fa-{icon} mr-1" />
          <span>{hed}</span>
        </button>
      {/each}
    </div>
  </div>

  <!-- Show Details Button -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <button
    type="button"
    class="btn btn-sm btn-primary mr-2"
    class:active={$show_metadata}
    title={translate("multiviewer.show_details")}
    on:click={() => ($show_metadata = !$show_metadata)}
  >
    <i class="fas fa-fw fa-info" />
    <span class="d-none d-sm-inline">
      {translate("multiviewer.show_details")}
    </span>
  </button>

  <!-- Add Series Button -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <button
    class="btn btn-sm btn-primary mr-2"
    type="button"
    title={translate("multiviewer.add_series")}
    on:click={() => updateQueryParameters({ modal: "AddSeriesModal" }, false)}
  >
    <i class="fas fa-fw fa-plus" />
    <span class="d-none d-sm-inline">
      {translate("multiviewer.add_series")}
    </span>
  </button>
  {#if $queryParameters.modal === "AddSeriesModal"}
    <AddSeriesModal />
  {/if}

  <!-- Share Button -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <button
    class="btn btn-sm btn-primary mr-2"
    on:click={handleShareBtnClick}
    title={translate("multiviewer.url_in_clipboard")}
  >
    <i class="fas fa-share-alt" />
    <span class="d-none d-sm-inline-block ml-1">{translate("share")}</span>
  </button>

  <!-- Info / Tutorial Button -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <button
    class="btn btn-sm btn-link"
    title={translate("multiviewer.tutorial.multiviewer_controls")}
    data-toggle="modal"
    data-target="#MultiviewerTutorialModal"
  >
    <i class="far fa-question-circle" />
  </button>
  <TutorialModal />

  <!-- Keyboard Shortcuts -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <button
    class="btn btn-sm btn-link"
    title={translate("multiviewer.tutorial.keyboard_shortcuts")}
    data-toggle="modal"
    data-target="#MultiviewerKeyboardShortcutModal"
  >
    <i class="far fa-keyboard" />
  </button>
  <KbShortcutsModal />
</header>
