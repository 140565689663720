// Include our external dependencies.
import jQuery from "jquery";
// import { queryParameters } from "../stores/router";

// Export the Svelte "Action" for modals.
export default function modalAction(node) {
  //
  // the node has been mounted in the DOM
  // cache some properties.
  const jModal = jQuery(node);
  const urlProp = `modal=${node.id}`;

  // Open or Close the Modal based on the URL.
  openOrCloseModalBasedOnURL();
  window.addEventListener("hashchange", openOrCloseModalBasedOnURL);
  function openOrCloseModalBasedOnURL() {
    jModal.modal(window.location.hash.includes(urlProp) ? "show" : "hide");
  }

  // When openned, add the Modal ID to the URL if it's not already present.
  jModal.on("show.bs.modal", () => {
    if (!window.location.hash.includes(urlProp)) {
      const prefix = window.location.hash.includes("?") ? "&" : "?";
      window.location.hash = [window.location.hash, prefix, urlProp].join("");
    }
  });

  // When closed, remove the Modal ID from the URL.
  jModal.on("hide.bs.modal", () => {
    window.location.hash = window.location.hash.replace(`&${urlProp}`, "");
    window.location.hash = window.location.hash.replace(urlProp, "");
  });

  return {
    destroy() {
      // …
      // the node has been removed from the DOM
      // …

      // Cleanup javascript events.
      window.removeEventListener("hashchange", openOrCloseModalBasedOnURL);

      // Bootstrap Modal cleanup.
      jModal.off("show.bs.modal");
      jModal.off("hide.bs.modal");
      jModal.modal("hide");
    },
  };
}
