export default function ParseMultipart(arrayBuffer, boundary) {
  const multipartBoundary = `--${boundary}`;
  const int8Array = new Uint8Array(arrayBuffer);
  let byteIndex = 0;
  let contentLength = 0;
  let endOfFileFound = false;
  const fileDatas = [];
  do {
    // Move the "byteIndex" up to the content by reading through the headers.
    progressThroughHeaders();

    // Capture the binary data content after the headers.
    fileDatas.push(int8Array.slice(byteIndex, byteIndex + contentLength));

    // Progress past the end of the content.
    byteIndex += contentLength;

    // Progress past the next boundary.
    progressThroughBoundary();
  } while (!endOfFileFound);

  return fileDatas;

  function progressThroughHeaders() {
    let currentChar;
    let line;
    let contentStartFound = false;
    do {
      currentChar = String.fromCharCode(int8Array[byteIndex]);
      switch (currentChar) {
        case "\n": {
          break;
        }
        case "\r": {
          if (line) {
            if (line.includes("Content-Length")) {
              contentLength = Number(line.split(":")[1]);
            }
          } else {
            // Two new lines in a row! We've reached the end of the headers!
            contentStartFound = true;
            byteIndex++;
          }
          line = "";
          break;
        }
        default: {
          line = [line, currentChar].join("");
        }
      }
      byteIndex++;
    } while (!contentStartFound);
  }

  function progressThroughBoundary() {
    let boundaryFound = false;
    let char = "";
    let line = "";
    do {
      char = String.fromCharCode(int8Array[byteIndex]);
      line = [line, char].join("");
      if (line.includes(multipartBoundary)) {
        boundaryFound = true;
        if (
          String.fromCharCode(int8Array[byteIndex + 1]) === "-" &&
          String.fromCharCode(int8Array[byteIndex + 2]) === "-"
        ) {
          endOfFileFound = true;
        }
        byteIndex++;
      }
      byteIndex++;
    } while (!boundaryFound);
  }
}
