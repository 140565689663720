// Import our external dependencies.
import once from "lodash-es/once";
import noop from "lodash-es/noop";
import { writable } from "svelte/store";
import offlineStorage from "store/dist/store.modern.min";

// Create a Svelte Store for user object. Auto-reflect to localstorage.
const user = writable(offlineStorage.get("user"));
user.subscribe(($user) => offlineStorage.set("user", $user));
export default user;

// Expose an additional function to track the user's activity!
export function onTenMinutesOfInactivity(callback) {
  if (!process.env.SESSION_INACTIVITY_TIMEOUT_MS) return noop;
  let timer = null;
  if (checkIt()) setupUserActivityEventListeners();
  return () => clearTimeout(timer);

  function checkIt() {
    const lastActiveAt =
      parseInt(offlineStorage.get("lastActivityAt"), 10) || Date.now();
    const activityDeadline =
      lastActiveAt + parseInt(process.env.SESSION_INACTIVITY_TIMEOUT_MS, 10);

    // If they're inactive now, call it!
    if (Date.now() >= activityDeadline) {
      callback();
      return false;
    }

    timer = setTimeout(checkIt, activityDeadline - Date.now());
    return true;
  }
}

const setupUserActivityEventListeners = once(() => {
  window.addEventListener("mousemove", markActivity);
  window.addEventListener("keypress", markActivity);
  window.addEventListener("click", markActivity);
  window.addEventListener("touchstart", markActivity);
  window.addEventListener("scroll", markActivity);
  window.addEventListener("hashchange", markActivity);
  let lastActivityAt =
    parseInt(offlineStorage.get("lastActivityAt"), 10) || Date.now();
  let waitingForRender = false;
  markActivity();
  function markActivity() {
    if (waitingForRender) return;
    waitingForRender = true;
    window.requestAnimationFrame(() => {
      waitingForRender = false;
      const now = Date.now();
      if (lastActivityAt === now) return;
      lastActivityAt = now;
      offlineStorage.set("lastActivityAt", lastActivityAt);
    });
  }
});
