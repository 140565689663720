<script>
  // Import our external dependencies.
  import map from "lodash-es/map";
  import { translate, loadLocale, locale, languages } from "i18n"; //eslint-disable-line import/no-unresolved
  import { api } from "../helpers/api";
  import user from "../stores/user";
  import { showAlert, dismissAlert } from "../stores/alerts";
  import { supportEmailHref } from "../preauth/preauth.svelte";
  import HyperfineLogoSVG from "../../../public/images/hyperfine-logo-gradient.svg";

  // External Props
  export let className = "";

  // Handle language selection
  function handleLanguageChange(event) {
    const { value } = event.target;
    loadLocale(value);
  }

  // Keep the user menu open when the language selector is clicked.
  function keepMenuOpen(event) {
    event.stopPropagation();
    return event;
  }

  async function handleUploadFilesSelected({ target: { files } }) {
    // Once the Hyperadmin has selected their files, prompt them to confirm
    // the upload, displaying the names of the files they've selected.
    const message = `Are you sure you want to upload these ${
      files.length
    } files?\n\n${map(files, (f) => `${f.name}\n`).join("")}`;
    if (!window.confirm(message)) return false; // eslint-disable-line no-alert

    // Show an alert that their upload is in progress.
    const uploadInProgAlert = {
      message: `Your ${files.length} files are being uploaded now…`,
      type: "primary",
      autodismiss_ms: 6500,
      dismissable: true,
    };
    showAlert(uploadInProgAlert);

    try {
      // Make multiple HTTP requests in parallel.
      await Promise.all(
        map(files, (file) => {
          return api(
            `/indigen/upload/${
              Object.keys($user.app_metadata.organization_attributes)[0]
            }`,
            {
              method: "POST",
              body: file,
              headers: {
                "Content-Type": "application/dicom",
              },
            }
          );
        })
      );

      // Yes it worked!
      dismissAlert(uploadInProgAlert);
      showAlert({
        message: "Uploads were a success! 😸",
        dismissable: true,
        type: "success",
      });
    } catch (err) {
      dismissAlert(uploadInProgAlert);
      showAlert({
        message: `Something went wrong 😿<br><br><pre><code>${err}</code></pre>`,
        dismissable: true,
        type: "danger",
      });
    }
  }

  function logout() {
    api.clearSession({ doReload: false });
    window.location = "#/";
  }
</script>

<header
  class="header | d-flex justify-content-between {className}"
  data-component="global-header"
>
  <div data-left class="d-flex align-items-center">
    <!-- Logo -->
    <a href="#/" class="d-inline-block home-link h-100">
      {@html HyperfineLogoSVG}
    </a>
  </div>
  <div data-right class="d-flex align-items-center">
    <!-- User Menu -->
    <div class="user-dropdown dropdown d-inline-block">
      <!-- Open/Close Button -->
      <button
        class="unbutton mr--2"
        type="button"
        id="GlobalUserMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fas fa-cog" />
      </button>

      <!-- Menu -->
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="GlobalUserMenu"
      >
        <label for="GlobalLanguageSelector">
          <h6 class="dropdown-header pl-3">
            {translate("label_chooose_language")}
          </h6>
        </label>
        <div class="px-3">
          <select
            class="form-control custom-select"
            id="GlobalLanguageSelector"
            value={locale}
            on:blur={handleLanguageChange}
            on:click={keepMenuOpen}
          >
            <option value={null}>{translate("label_chooose_language")}</option>
            {#if languages}
              {#each languages as { name, id }, i (id)}
                <option value={id}>{name}</option>
              {/each}
            {/if}
          </select>
        </div>

        <!-- Log In/Out -->
        <div class="dropdown-divider" />
        {#if $user}
          <h6 class="dropdown-header">Hi, {$user.app_metadata.user_name} 👋</h6>
          <a href="#/" class="dropdown-item" on:click={logout}>
            <span>
              <i class="mr-1 fas fa-fw fa-sign-out-alt" />
              {translate("form_labels.log_out")}
            </span>
          </a>
        {:else}
          <a class="dropdown-item unlink" href="#/login">
            <span>
              <i class="mr-1 fas fa-fw fa-sign-out-alt" />
              {translate("form_labels.log_in")}
            </span>
          </a>
        {/if}

        <!-- Report an Issue -->
        <a class="dropdown-item" href={supportEmailHref}>
          <span>
            <i class="mr-1 fas fa-fw fa-bug" />
            {translate("form_labels.report_issue")}
          </span>
        </a>

        <!-- About -->
        <a class="dropdown-item unlink" href="#/about">
          <span>
            <i class="mr-1 fas fa-fw fa-address-card" />
            {translate("about.dek")}
          </span>
        </a>

        <!-- Hyperadmin Only Tools -->
        {#if $user.app_metadata.is_hyperfine_admin}
          <div class="dropdown-divider" />
          <h6 class="dropdown-header pl-3 text-primary">Hyperadmin Tools</h6>
          <input
            type="file"
            class="d-none"
            id="inputGroupFile01"
            accept=".dcm,.dicom"
            multiple="true"
            on:change={handleUploadFilesSelected}
          />
          <label
            class="dropdown-item unlink text-primary"
            for="inputGroupFile01"
          >
            <span>
              <i class="mr-1 fas fa-fw fa-file-upload" />
              Upload DICOM
            </span>
          </label>
        {/if}

        <!-- Legal & Policies -->
        <div class="dropdown-divider" />
        <div class="policies dropdown-item small py-0">
          <small>
            {translate("about.non_diagnostic_disclaimer")}
          </small>
        </div>
      </div>
    </div>
  </div>
</header>

<style type="text/scss">
  @import "bootstrap/variables";
  $header-horizontal-padding: map-get($spacers, 3);
  .header {
    height: $global-header-height;
    background-color: $gray-950;
    border-bottom: 1px solid $black;
    padding: map-get($spacers, 2) $header-horizontal-padding;
  }
  .user-dropdown {
    .dropdown-menu {
      width: 300px;
    }
    .policies {
      white-space: normal;
      &:hover {
        background-color: transparent;
      }
    }
  }
  :global(header[data-component="global-header"] .home-link svg) {
    height: $global-header-height - $header-horizontal-padding;
  }
</style>
