<script context="module">
  // Link to send feedback to dev team.
  export const supportEmailHref = `mailto:cloud-support@hyperfine-research.com?Subject=User%20Feedback%20-%20Indigen%20v${process.env.VERSION}`;
  const cpa_url = process.env.AM_URL;
  const version = process.env.VERSION;
</script>

<script>
  // Import our external dependencies.
  import { translate, languages, loadLocale } from "i18n"; //eslint-disable-line import/no-unresolved
  import LoginForm from "./login-form.svelte";
  import HyperfineLogoSVG from "../../../public/images/hyperfine-logo-gradient.svg";

  let email = "";
  $: resetHref = `${cpa_url}/password-reset?email=${encodeURIComponent(email)}`;
</script>

<div class="min-vh-100 d-flex flex-column" data-component="preauth">
  <section class="py-5 my-auto">
    <div class="mx-auto container-fluid skinny-pete">
      <div class="row">
        <div class="col-12 pb-5 mx-auto text-center">
          <a href="#/" class="hyperlogo d-inline-block w-100">
            {@html HyperfineLogoSVG}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <LoginForm bind:email />
          <a href={resetHref} class="btn btn-sm btn-link btn-block mt-3">
            <span>Reset Your Password</span>
          </a>
        </div>
      </div>
    </div>
  </section>

  <!-- Footer - Languages & Useage Policies -->
  <footer class="mt-auto container-fluid py-1 border-top">
    <div class="row align-items-center">
      <div class="col-auto mr-auto">
        {#each languages as { name, id } (id)}
          <button
            class="btn btn-link btn-sm"
            on:click={(event) => loadLocale(id)}
          >
            <span>{name}</span>
          </button>
        {/each}
      </div>
      <div class="col-12 col-lg text-lg-center small">
        v{version}
      </div>
      <div class="col-12 col-lg-auto">
        <a href={supportEmailHref} class="btn btn-link btn-sm">
          {translate("form_labels.report_issue")}
        </a>
      </div>
    </div>
  </footer>
</div>

<style>
  .skinny-pete {
    max-width: 600px;
  }
  .hyperlogo {
    max-width: 400px;
  }
</style>
