<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { version } from "../../../package.json";
  import { api } from "../helpers/api";

  const timeoutMS = process.env.SESSION_INACTIVITY_TIMEOUT_MS;

  function getAdditionalPages() {
    return [
      {
        name: translate("about.log_in"),
        url: "#/login",
      },
    ];
  }

  const built_with = [
    {
      name: "Progressive Web App",
      icon: "images/lib-logos/pwa.svg",
      url: "https://developers.google.com/web/progressive-web-apps",
    },
    {
      name: "Svelte",
      icon: "images/lib-logos/svelte.svg",
      url: "https://svelte.dev/",
    },
    {
      name: "Bootstrap",
      icon: "images/lib-logos/bootstrap.svg",
      url: "https://www.getbootstrap.com/",
    },
    {
      name: "jQuery",
      icon: "images/lib-logos/jquery.svg",
      url: "https://jquery.com/",
    },
    {
      name: "WebGL",
      icon: "images/lib-logos/webgl.svg",
      url: "https://www.khronos.org/webgl/",
    },
    {
      name: "Node",
      icon: "images/lib-logos/node.svg",
      url: "https://nodejs.org/en/",
    },
    {
      name: "Workbox",
      icon: "images/lib-logos/workbox-96.png",
      icon_2x: "images/lib-logos/workbox-192.png",
      url: "https://developers.google.com/web/tools/workbox/",
    },
    {
      name: "Rollup",
      icon: "images/lib-logos/rollup.svg",
      url: "https://rollupjs.org/guide/en/",
    },
    {
      name: "Babel",
      icon: "images/lib-logos/babel.svg",
      url: "https://babeljs.io/",
    },
    {
      name: "ESLint",
      icon: "images/lib-logos/eslint.svg",
      url: "https://eslint.org/",
    },
  ];

  async function handleUninstall() {
    if (!window.confirm(translate("about.uninstall_confirm"))) return false; // eslint-disable-line no-alert
    await window.uninstallIndigen();
    api.clearSession({ doReload: false });
  }
</script>

<section
  class="about-component container-fluid fade-in pb-3"
  data-component="about"
>
  <div class="row">
    <!-- Summary Panel -->
    <div class="col-md-5 col-xl-4 | pb-4">
      <section class="summary | p-4 border rounded position-relative shadow">
        <div class="title-bgblock left-0 top-0 position-absolute w-100" />
        <h1 class="position-relative">{translate("app_name")}</h1>
        <span class="badge badge-primary mr-2">{translate("about.hed")}</span>
        <div class="details-list | mt-4 mt-md-5">
          <small class="text-muted text-capitalize letter-space-1 d-block mb-2">
            {translate("overview", 1)}
          </small>
          <div class="row d-flex justify-content-between py-1">
            <span class="col-auto text-capitalize">{translate("version")}</span>
            <span class="col text-right text-truncate">{version}</span>
          </div>
          <div class="row d-flex justify-content-between py-1">
            <span class="col-auto text-capitalize">Inactivity Timeout</span>
            <span class="col text-right text-truncate">
              {timeoutMS
                ? translate(
                    "minutes",
                    Math.round(
                      parseInt(process.env.SESSION_INACTIVITY_TIMEOUT_MS, 10) /
                        600
                    ) / 100
                  )
                : "∞"}
            </span>
          </div>
        </div>

        <div class="details-list | mt-3">
          <small class="text-muted text-capitalize letter-space-1 d-block mb-2">
            {translate("other_pages")}
          </small>
          {#each getAdditionalPages() as { name, url } (url)}
            <div
              class="row d-flex justify-content-between py-1 position-relative"
            >
              <span class="col-auto text-capitalize">{name}</span>
              <a class="col text-right text-truncate stretched-link" href={url}>
                {url}
              </a>
            </div>
          {/each}
        </div>

        <div class="mt-4">
          {#if "serviceWorker" in navigator}
            <button
              type="button"
              class="btn btn-sm btn-outline-warning mr-3"
              on:click={handleUninstall}
            >
              {translate("about.uninstall")}
              <i class="fas fa-trash small ml-2" />
            </button>
          {/if}
          <button
            type="button"
            class="btn btn-sm btn-outline-info"
            on:click={() => alert(`${translate("about.love_you")} 😘`)}
          >
            {translate("about.love_me")}
            <i class="fas fa-heart small ml-2" />
          </button>
        </div>
      </section>
    </div>

    <!-- Details Section -->
    <div class="col-md-7 col-xl-8">
      <!-- Sub Routing -->
      <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  --->

      <!-- Under The Hood -->
      <div class="px-3">
        <p>{translate("about.give_thanks")}</p>
        <div class="container-fluid">
          <div class="row text-center">
            {#each built_with as { name, icon, icon_2x, url } (url)}
              <div
                class="col-6 col-lg-4 col-xl-3 col-xxl-2 mb-5 position-relative
                d-flex flex-column"
              >
                <div class="d-flex flex-grow-1 mb-3">
                  {#if icon_2x}
                    <img
                      class="dep-logo my-auto mx-auto"
                      src={icon_2x}
                      alt="{name} Logo"
                      srcset="{icon}, {icon_2x} 2x"
                    />
                  {:else}
                    <img
                      class="dep-logo my-auto mx-auto"
                      src={icon}
                      alt="{name} Logo"
                    />
                  {/if}
                </div>
                <a class="h6 stretched-link" href={url}>{name}</a>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";

  .about-component {
    padding-top: $global-header-height + map-get($spacers, 3);
  }

  .summary,
  .overline-row:hover {
    border-top: 1px solid $primary !important;
  }
  .overline-row {
    transition: border-top-color 0.2s ease-out;
  }
  .secondary-nav .nav-link {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 200ms ease-out;
    &.active {
      border-bottom-color: $primary;
    }
  }
  .dep-logo {
    max-height: 100px;
    max-width: 100%;
  }
</style>
