<script>
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import debounce from "lodash-es/debounce";
  import { onDestroy, setContext } from "svelte";
  import VolumeViewer from "volume-viewer"; //eslint-disable-line import/no-unresolved
  import { api, OrgQP } from "../helpers/api";
  import parseMultipart from "../helpers/parse-multipart";
  import { showAlert } from "../stores/alerts";
  import user from "../stores/user";
  import { queryParameters, updateQueryParameters } from "../stores/router";
  import Toolbar from "./multiviewer-toolbar.svelte";
  import Viewer, { reflectedProps } from "./viewer.svelte";
  import DicomDetailsModal from "./dicom-details-modal.svelte";

  // Load Whole Studies
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |

  // Parse Studies from the URL.
  $: study_uids_qp = $queryParameters.studies || "";
  $: study_uids = study_uids_qp.split(",").filter((s) => !!s);

  // Load the Studies via DICOMweb.
  const study_datas = {};
  $: loadStudies(study_uids);
  function loadStudies() {
    study_uids.forEach((study_uid) => {
      // Remove the Study ID from the URL.
      updateQueryParameters({
        studies: $queryParameters.studies.replace(study_uid, "") || null,
      });

      // Prevent duplicate loading.
      if (study_datas[study_uid]) return;

      // Execute the HTTP request for the whole study binary…
      study_datas[study_uid] = api(
        `/studies/${study_uid}?${
          $user.app_metadata.is_hyperfine_admin ? "" : $OrgQP
        }`,
        {
          headers: { Accept: "*/*" },
        },
        false,
        true
      )
        // …parse the HTTP response…
        .then((study_resp) => {
          const boundary = study_resp.headers
            .get("content-type")
            .split("boundary=")[1];
          return study_resp.arrayBuffer().then((arrayBuffer) => {
            return { boundary, arrayBuffer };
          });
        })

        // …create image data from the response binary…
        .then(({ boundary, arrayBuffer }) => {
          const sections = parseMultipart(arrayBuffer, boundary);

          // Inject each series into the data dictionary and prepare their UIDs.
          const series_uids = sections.map((section) => {
            const dataView = new DataView(section.buffer);
            const series = window.daikon.Series.parseImage(dataView);
            series.volume = VolumeViewer.Volume.loadVolumeDicom([
              dataView.buffer,
            ]);
            const series_uid = `${study_uid}:${series.getSeriesInstanceUID()}`;
            series_promises[series_uid] = Promise.resolve(series);
            return series_uid;
          });

          // Inject the study IDs into the URL.
          const series_qp_start = $queryParameters.series
            ? `${$queryParameters.series},`
            : "";
          updateQueryParameters({
            series: `${series_qp_start}${series_uids.toString()}`,
          });
        })

        // …handle errors.
        .catch((err) => showAlert({ message: err }));
    });
  }

  // Load Individual Series
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |

  // Parse Series from the URL.
  $: series_uids_qp = $queryParameters.series || "";
  $: series_uids = series_uids_qp.split(",").filter((s) => !!s);

  // Load the Series via DICOMweb.
  const series_promises = {};
  setContext("series_promises", series_promises);
  $: loadSeries(series_uids);
  function loadSeries() {
    series_uids.forEach((series_and_study_uids) => {
      // Stop execution if this series is already loaded or being loaded.
      if (series_promises[series_and_study_uids]) return;

      // Read the Study and Series IDs out of the entry. Reject if malformed.
      const [study_uid, series_uid] = series_and_study_uids.split(":");
      if (!study_uid || !series_uid)
        return (series_promises[series_and_study_uids] = Promise.reject());

      series_promises[series_and_study_uids] = api(
        `/studies/${study_uid}/series/${series_uid}?${
          $user.app_metadata.is_hyperfine_admin ? "" : $OrgQP
        }`,
        {
          headers: { Accept: "*/*" },
        },
        false,
        true
      )
        .then((resp) => resp.arrayBuffer())
        .then((arrayBuffer) => {
          const dataView = new DataView(arrayBuffer);
          const series = window.daikon.Series.parseImage(dataView);
          series.volume = VolumeViewer.Volume.loadVolumeDicom([
            dataView.buffer,
          ]);
          return series;
        })
        .catch((err) => showAlert({ message: err }));
    });
  }

  // Dynamic column classname for responsiveness.
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  $: computePanelSizes(series_uids.length);
  let col_class = "col";
  function computePanelSizes() {
    if (series_uids.length < 3) {
      // 1 or 2
      col_class = "col-12 col-sm-6";
    } else if (series_uids.length < 4) {
      // 3
      col_class = "col-12 col-sm-6 col-lg-4";
    } else if (series_uids.length < 5) {
      // 4
      col_class = "col-6";
    } else if (series_uids.length < 6) {
      // 5
      col_class = "col-6 col-lg-4 col-xxl";
    } else if (series_uids.length < 7) {
      // 6
      col_class = "col-6 col-lg-4";
    } else if (series_uids.length < 9) {
      // 7 or 8
      col_class = "col-6 col-lg-3";
    } else if (series_uids.length < 10) {
      // 9
      col_class = "col-4";
    } else if (series_uids.length < 13) {
      // 10-12
      col_class = "col-4 col-md-3";
    } else {
      // 13+
      col_class = "col-4 col-md-3 col-xl-2";
    }
  }

  // Additional Click & Event Handlers
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  function handleClose(index) {
    // Decriment all of the reflected prop query parameters.
    const qps = Object.values(reflectedProps).reduce((qps, propName) => {
      // loop through prop names
      qps[`${propName}${index}`] = null;
      for (let i = index + 1, l = series_uids.length; i < l; i++) {
        // loop through indexes
        qps[`${propName}${i - 1}`] =
          $queryParameters[`${propName}${i}`] || null;
      }
      qps[`${propName}${series_uids.length - 1}`] = null;
      return qps;
    }, {});

    // Remove the specified series from series list (panels).
    series_uids.splice(index, 1);
    qps.series = series_uids.toString();
    updateQueryParameters(qps, false);
  }
  function handleExpand(index) {
    // Move this index's query parameters and remove others.
    const qps = Object.values(reflectedProps).reduce((qps, propName) => {
      qps[`${propName}0`] = $queryParameters[`${propName}${index}`];
      for (let i = 1, l = series_uids.length; i < l; i++) {
        qps[`${propName}${i}`] = null;
      }
      return qps;
    }, {});
    qps.series = series_uids[index];
    updateQueryParameters(qps, false);
  }
  const handleReflect = (function GenerateDebouncedHandleReflectFunction() {
    let newQPs = {};
    let isDestroyed = false;
    onDestroy(() => (isDestroyed = true));
    const debounced_url_reflection = debounce(() => {
      if (isDestroyed) return false;

      // convert queued values into strings for the URL.
      const stringifiedQPs = {};
      Object.keys(newQPs).forEach((key) => {
        let value = newQPs[key];
        const dataType = typeof value;
        if (value === false) value = null;
        else if (dataType === "number")
          value = value === 0 ? null : value.toFixed(6);
        else if (dataType === "object" && value !== null)
          value = JSON.stringify(value);
        stringifiedQPs[key] = value;
      });
      updateQueryParameters(stringifiedQPs);
      newQPs = {};
    }, 300);

    return ({ name, value }, index = "") => {
      newQPs[`${name}${index}`] = value;
      debounced_url_reflection();
    };
  })();

  // Global URL Parameters & Reflection
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // Global Scroll
  $: url_globalScrollZ = $queryParameters[reflectedProps.scrollZ];
  $: url_globalScrollY = $queryParameters[reflectedProps.scrollY];
  $: url_globalScrollX = $queryParameters[reflectedProps.scrollX];
  $: globalScrollZ = parseFloat(url_globalScrollZ) || 0.0;
  $: globalScrollY = parseFloat(url_globalScrollY) || 0.0;
  $: globalScrollX = parseFloat(url_globalScrollX) || 0.0;
  $: handleReflect({ name: reflectedProps.scrollZ, value: globalScrollZ });
  $: handleReflect({ name: reflectedProps.scrollY, value: globalScrollY });
  $: handleReflect({ name: reflectedProps.scrollX, value: globalScrollX });

  // Global Rotation
  $: url_globalRotation = $queryParameters[reflectedProps.rotation];
  $: globalRotation = url_globalRotation
    ? JSON.parse(url_globalRotation)
    : null;
  $: handleReflect({ name: reflectedProps.rotation, value: globalRotation });

  // Global Pan (x and y)
  $: url_globalPanX = $queryParameters[reflectedProps.panX];
  $: url_globalPanY = $queryParameters[reflectedProps.panY];
  $: globalPanX = parseFloat(url_globalPanX) || 0.0;
  $: globalPanY = parseFloat(url_globalPanY) || 0.0;
  $: handleReflect({ name: reflectedProps.panX, value: globalPanX });
  $: handleReflect({ name: reflectedProps.panY, value: globalPanY });

  // Global Zoom
  $: url_globalZoom = $queryParameters[reflectedProps.zoom];
  $: globalZoom = parseFloat(url_globalZoom) || false;
  $: handleReflect({ name: reflectedProps.zoom, value: globalZoom });

  // Misc
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // This function is called from a loop in the template.
  // By computing the identifier, we can allow for duplicate series (IDs) to be opened.
  const getSeriesLoopIdentifier = (function getSeriesLoopIdentifier_closure() {
    let idCheckCount = 0;
    let deduplicator = {};
    return function getSeriesLoopIdentifier(id, maxCount) {
      // Return the ID or a modified ID if this is a duplicate series.
      let identifier;
      if (!deduplicator[id]) {
        deduplicator[id] = 1;
        identifier = id;
      } else {
        deduplicator[id]++;
        identifier = `${id}_${deduplicator[id]}`;
      }

      // Reset the deduplicator once we've checked all the series.
      idCheckCount++;
      if (idCheckCount >= maxCount) {
        idCheckCount = 0;
        deduplicator = {};
      }

      return identifier;
    };
  })();

  // Trigger Re-renders if the panel sizes change.
  let renderTrigger = 0;
  let sWidth = 0;
  let sHeight = 0;
  $: if (renderTrigger || sWidth || sHeight || series_uids) renderTrigger++;
</script>

<svelte:window bind:innerWidth={sWidth} bind:innerHeight={sHeight} />
<div class="NEWmultiviewer vh-100 container-fluid px-0">
  <!-- Toolbar -->
  <div class="toolbar">
    <Toolbar />
  </div>

  <!-- Series Panels -->
  <div
    class="series-panels container-fluid px-0 position-relative overflow-hidden count-{series_uids.length}"
  >
    <div class="series-row row no-gutters">
      {#each series_uids as series_uid, i (getSeriesLoopIdentifier(series_uid, series_uids.length))}
        <div
          class="panel bg-black text-center border-top border-right position-relative {col_class}"
        >
          <Viewer
            series_promise={series_promises[series_uid]}
            on:close={() => handleClose(i)}
            on:expand={() => handleExpand(i)}
            on:reflect={({ detail }) => handleReflect(detail, i)}
            on:showDicomFields={() =>
              updateQueryParameters({
                modal: `DicomDetailsModal${series_uid}`,
              })}
            bind:globalScrollX
            bind:globalScrollY
            bind:globalScrollZ
            bind:globalRotation
            bind:globalPanX
            bind:globalPanY
            bind:globalZoom
            scrollX={$queryParameters[`${reflectedProps.scrollX}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.scrollX}${i}`])
              : false}
            scrollY={$queryParameters[`${reflectedProps.scrollY}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.scrollY}${i}`])
              : false}
            scrollZ={$queryParameters[`${reflectedProps.scrollZ}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.scrollZ}${i}`])
              : false}
            viewMode={$queryParameters[`${reflectedProps.viewMode}${i}`] ||
              "2D"}
            isInterpolated={$queryParameters[
              `${reflectedProps.isInterpolated}${i}`
            ] !== "false"}
            rotation={$queryParameters[`${reflectedProps.rotation}${i}`]
              ? JSON.parse($queryParameters[`${reflectedProps.rotation}${i}`])
              : null}
            brightness={$queryParameters[`${reflectedProps.brightness}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.brightness}${i}`])
              : false}
            contrast={$queryParameters[`${reflectedProps.contrast}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.contrast}${i}`])
              : false}
            zoom={$queryParameters[`${reflectedProps.zoom}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.zoom}${i}`])
              : false}
            panX={$queryParameters[`${reflectedProps.panX}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.panX}${i}`])
              : false}
            panY={$queryParameters[`${reflectedProps.panY}${i}`]
              ? parseFloat($queryParameters[`${reflectedProps.panY}${i}`])
              : false}
            clipStr={$queryParameters[`${reflectedProps.clipStr}${i}`] || ""}
            colorMap2D={$queryParameters[`${reflectedProps.colorMap2D}${i}`] ||
              ""}
            colorMapMPR={$queryParameters[
              `${reflectedProps.colorMapMPR}${i}`
            ] || ""}
            colorMap3D={$queryParameters[`${reflectedProps.colorMap3D}${i}`] ||
              ""}
            {renderTrigger}
          />

          <!-- Not For Diagnostic Use labeling -->
          <aside
            class="position-absolute bottom-0 make-horizontal w-0 text-center"
          >
            <small class="words">{translate("non_diagnostic_only")}</small>
          </aside>
        </div>
      {/each}
    </div>

    <!-- Loading Overlay for Whole Studies -->
    {#await Promise.all(Object.values(study_datas))}
      <div
        class="loading-overlay position-absolute top-0 left-0 right-0 bottom-0 w-100 h-100"
      >
        <h1 class="text-center pt-5 pb-3">Loading Studies</h1>
        <div class="text-center spinner-delayed">
          <div class="spinner-border" role="status">
            <span class="sr-only">
              {translate("notifications.loadingDotDotDot")}
            </span>
          </div>
        </div>
      </div>
    {:then}
      {#if series_uids.length === 0}
        <div
          class="loading-overlay position-absolute top-0 left-0 right-0 bottom-0 w-100 h-100 text-center"
        >
          <h1 class="pt-5 pb-3">Select Some Studies To View</h1>
          <a
            class="btn btn-primary btn-lg"
            href="#/multiviewer?modal=AddSeriesModal"
          >
            <span>Add Series</span>
          </a>
        </div>
      {/if}
    {:catch err}
      <!-- Error Message for Whole Studies -->
      <div
        class="loading-overlay position-absolute top-0 left-0 right-0 bottom-0 w-100 h-100"
      >
        <h1 class="text-center pt-5 pb-3">
          There was an error!
          {@html err}
        </h1>
      </div>
    {/await}
  </div>

  <!-- DICOM Details Modal -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  {#if $queryParameters.modal && $queryParameters.modal.includes("DicomDetailsModal")}
    {#await series_promises[$queryParameters.modal.replace("DicomDetailsModal", "")] then series}
      <DicomDetailsModal
        {series}
        uid={$queryParameters.modal.replace("DicomDetailsModal", "")}
      />
    {/await}
  {/if}
</div>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  $seriesColor: map-get($module-colors, "series");
  $toolbar-height: 2.9375rem;

  .NEWmultiviewer {
    padding-top: $global-header-height;
  }
  .toolbar {
    height: $toolbar-height;
  }
  .series-panels {
    height: calc(100% - #{$toolbar-height});
  }
  .panel {
    border-top-color: $seriesColor !important;
    border-top-width: 2px !important;
    border-right-color: $gray-700 !important;
    &:last-child {
      flex-basis: 0 !important;
      flex-grow: 1 !important;
      min-width: 0 !important;
      max-width: 100% !important;
    }
  }
  .series-row {
    width: calc(100% + 1px);
    height: 100%;
    // height: calc(100% + 1px);
  }
  .loading-overlay {
    background-color: rgba(0, 0, 0, 0.65);
  }
  .make-horizontal {
    left: 50%;
    .words {
      display: inline-block;
      transform: translate3d(-50%, 0, 0);
      white-space: nowrap;
    }
  }
</style>
