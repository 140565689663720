<svelte:options immutable={true} />

<script>
  // Import our external dependencies.
  import { studySearchableDicomDictionary } from "../helpers/dicomweb";
  import { createEventDispatcher } from "svelte";

  export let dicomTag = "";

  const dispatch = createEventDispatcher();

  function handleDicomTagChange() {
    dispatch("dicomTagChange", dicomTag);
  }
</script>

<!-- svelte-ignore a11y-no-onchange -->
<select
  class="custom-select border-primary"
  title="Filter to a specific Organization!"
  bind:value={dicomTag}
  on:change={handleDicomTagChange}
>
  <option value={null}>Tags</option>
  {#each Object.keys(studySearchableDicomDictionary) as tag (tag)}
    <option value={tag}>{tag}</option>
  {/each}
</select>
