// Import our external dependencies.
// …

// DICOM Key Dictionary
export const dicomDictionary = {
  "0020000D": "StudyInstanceUID",
  "00080005": "SpecificCharacterSet",
  "00080020": "StudyDate",
  "00080030": "StudyTime",
  "00080050": "AccessionNumber",
  "00080090": "ReferringPhysicianName",
  "00081190": "RetrieveURL",
  "00100010": "PatientName",
  "00100020": "PatientID",
  "00100030": "PatientBirthDate",
  "00100040": "PatientSex",
  "00104000": "PatientComments",
  "00101030": "PatientWeight",
  "00101010": "PatientAge",
  "00200010": "StudyID",
  "00201206": false,
  "00201208": false,
  "00081030": "StudyDescription",
  "00080008": "ImageType",
  "00180020": "SeriesningSequence",
  "00180024": "SequenceName",
  "00180087": "MagneticFieldStrength",
  "00080061": "SeriesType",
  "00180022": "SeriesOptions",
  "00180086": "EchoNumbers",
  "0020000E": "SeriesInstanceUID",
  "0008103E": "SeriesDescription",
  "00080060": "Modality",
  "00200011": "SeriesNumber",
  "00201209": false,
  "00400244": "PerformedProcedureStepStartDate",
  "00400245": "PerformedProcedureStepStartTime",
  "00142018": "IndicationDescription",
  "00081050": "PerformingPhysicianName",
  "00204000": "ImageComments",
  "00080016": "SOPClassUID",
  "00080018": "SOPInstanceUID",
  "00080070": "Manufacturer",
  "00200013": "InstanceNumber",
  "00280301": "BurnedInAnnotation",
  "0008002A": "AcquisitionDateTime",
  "00200020": "PatientOrientation",
  "00080023": "ContentDate",
  "00080021": "SeriesDate",
  "00280103": "PixelRepresentation",
  "00280100": "BitsAllocated",
  "00280101": "BitsStored",
  "00280102": "HighBit",
  "00281050": "WindowWidth",
  "00280002": "SamplesPerPixel",
  "00280010": "Rows",
  "00280011": "Columns",
  "00089206": "VolumetricProperties",
  "00089208": "ComplexImageComponent",
  "00200052": "FrameOfReferenceUID",
  "00185100": "PatientPosition",
  "00081090": "ManufacturerModelName",
  "00181009": "UniqueDeviceIdentifier",
  "00181020": "SoftwareVersions",
  "00201040": "PositionReferenceIndicator",
  "00180023": "MRAcquisitionType",
  "00189008": "EchoPulseSequence",
  "00189012": "MultiPlanarExcitation",
  "00189014": "PhaseContrast",
  "00189015": "TimeOfFlightContrast",
  "00189017": "SteadyStatePulseSequence",
  "00189011": "MultipleSpinEcho",
  "00189018": "EchoPlanarPulseSequence",
  "00189024": "SaturationRecovery",
  "00189025": "SpectrallySelectedSuppression",
  "00189029": "OversamplingPhase",
  "00189032": "GeometryOfKSpaceTraversal",
  "00189033": "SegmentedKSpaceTraversal",
  "00189093": "NumberOfKSpaceTrajectories",
  20500020: "PresentationLUTShape",
  "00282112": "LossyImageCompression",
  "00089209": "AcquisitionContrast",
  "00189174": "ApplicableSafetyStandardAgency",
  "00189064": "KSpaceFiltering",
  "00189100": "ResonantNucleus",
  "00189034": "RectilinearPhaseEncodeReordering",
  "00189004": "ContentQualification",
  "00400555": "AcquisitionContextSequence",
  "7FE00010": "PixelData",
  52009230: "PerFrameFunctionalGroupsSequence",
  "00180050": "SliceThickness",
  "00280030": "PixelSpacing",
  "00200037": "PlaneOrientationSequence",
  "00281052": "RescaleIntercept",
  "00281053": "RescaleSlope",
  "00281054": "RescaleType",
  "00209072": "FrameLaterality",
  "00080100": "CodeValue",
  "00080104": "CodeMeaning",
  "00080102": "CodingSchemeDesignator",
  "00181312": "InPlanePhaseEncodingDirection",
  "00189058": "MRAcquisitionFrequencyEncodingSteps",
  "00189231": "MRAcquisitionPhaseEncodingStepsInPlane",
  "00180093": "PercentSampling",
  "00180094": "PercentPhaseFieldOfView",
  "00189125": "MRFOVGeometrySequence",
  "00180083": "NumberOfAverages",
  "00189009": "InversionRecovery",
  "00180082": "InversionTime",
  "00080080": "InstitutionName",
  "00080081": "InstitutionAddress",
  "00081040": "InstitutionDepartmentName",
  "00081010": "StationName",
  "00180021": "SequenceVariant",
  "00189073": "AcquisitionDuration",
  "00189005": "PulseSequenceName",
  "00180081": "EchoTime",
  "00180080": "RepetitionTime",
  "00181000": "DeviceSerialNumber",
  "00180084": "ImagingFrequency",
  "00181251": "TransmitCoilName",
};

// Objects that contains the searchable dicomtags and whether a wildcard query should be automatically made
export const studySearchableDicomDictionary = {
  StudyInstanceUID: false,
  StudyDate: false,
  StudyTime: false,
  PatientName: true,
  PatientID: true,
  PatientBirthDate: false,
  PatientSex: true,
  PatientWeight: true,
  PatientComments: true,
  PatientAge: false,
  StudyDescription: true,
};

// Helper to include wildcard to DicomTag if necessary
export function addWildcardTag(dicomTag, searchTxt) {
  if (studySearchableDicomDictionary[dicomTag]) {
    return `*${searchTxt}*`;
  }
  return searchTxt;
}

// Parser Logic
export function parseDICOMwebJSON(rawArray) {
  const parsedArray = [];

  // Loop through the objects and turn them into something meaningful.
  for (let i = 0, l = rawArray.length, rawDicomObj; i < l; i++) {
    rawDicomObj = rawArray[i];

    const parsedObj = {};

    Object.keys(rawDicomObj).forEach((key) => {
      const propertyName = dicomDictionary[key];
      let value = rawDicomObj[key];

      // Handle missing values.
      if (!value || !value.Value)
        return (parsedObj[propertyName || key] = null);

      switch (value.vr) {
        // case "UI": { }
        // case "CS": { }
        case "DA": {
          // Date
          value.Value = value.Value[0];

          // Convert from DICOM format to ISO format
          const year = value.Value.substring(0, 4);
          const month = value.Value.substring(4, 6);
          const day = value.Value.substring(6, 8);
          value = `${year}-${month}-${day}T00:00:00`;
          break;
        }
        case "DT": {
          // Date Time
          value.Value = value.Value[0];

          // Convert from DICOM format to ISO format
          const year = value.Value.substring(0, 4);
          const month = value.Value.substring(4, 6);
          const day = value.Value.substring(6, 8);
          const hour = value.Value.substring(8, 10);
          const minute = value.Value.substring(10, 12);
          const second = value.Value.substring(12, 14);

          // Determine the offset.
          let offset = "";
          let offset_pieces = value.Value.split("+");
          if (offset_pieces.length > 1) offset = "+";
          else {
            offset_pieces = value.Value.split("-");
            if (offset_pieces.length > 1) offset = "-";
          }
          if (offset) {
            const offset_hours = offset_pieces[1].substring(0, 2);
            const offset_minutes = offset_pieces[1].substring(2, 4);
            offset = `${offset}${offset_hours}:${offset_minutes}`;
          }

          // Put the ISO 8601 string together.
          value = `${year}-${month}-${day}T${hour}:${minute}:${second}${offset}`;
          break;
        }
        case "PN": {
          // Persons Name
          if (value && value.Value)
            value = value.Value.map((item) => item.Alphabetic).toString();
          else value = null;
          break;
        }
        default: {
          value = value.Value ? value.Value.toString() : null;
          break;
        }
      }

      return (parsedObj[propertyName || key] = value);
    });

    parsedArray.push(parsedObj);
  }

  return parsedArray;
}

// Sort function(s)
export function sortByAcquisitionDesc(first, second) {
  if (!first.AcquisitionDateTime) return true;
  if (!second.AcquisitionDateTime) return false;
  return first.AcquisitionDateTime < second.AcquisitionDateTime;
}
