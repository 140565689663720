// Import our external dependencies.
import { writable } from "svelte/store";

// Setup the Store with a local backup;
let _alerts = [];
export const alerts = writable(_alerts);
alerts.subscribe(($alerts) => (_alerts = $alerts));

// Helper Functions
export function showAlert(alert) {
  // Set a timer to auto-dismiss, if applicable.
  if (alert.autodismiss_ms) {
    alert.dismissTimer = setTimeout(
      () => dismissAlert(alert),
      alert.autodismiss_ms
    );
  }

  _alerts = [alert, ..._alerts];
  alerts.set(_alerts);
}
export function dismissAlert(alertToDismiss) {
  // Destroy the auto-dismiss timer if present!
  if (alert.dismissTimer) clearTimeout(alert.dismissTimer);

  _alerts = _alerts.filter((alert) => alert !== alertToDismiss);
  alerts.set(_alerts);
}
