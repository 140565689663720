<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
</script>

<section
  class="d-flex flex-grow-1 justify-content-around align-items-center"
  data-component="four-oh-four"
>
  <div
    class="border-dashed p-5 text-center text-muted border border-secondary
    rounded m-3"
  >
    <h2>{translate("404")}</h2>
  </div>
</section>
