<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { refreshRoute, route0 } from "../stores/router";
  import { api } from "../helpers/api";
  import { showAlert } from "../stores/alerts";

  // Form Fields
  export let email = "";
  let password = "";

  // Form Submission
  let isInFlight = false;
  let errorMessage = false;
  async function handleSubmit() {
    // Check form validity.
    if (!this.checkValidity()) return;

    // Update the UI to show we're in-flight.
    isInFlight = true;
    errorMessage = false;

    // Make the HTTP request and update the DOM.
    await api
      .authenticate({ email, password })
      .then(handleSuccess)
      .catch((err) => (errorMessage = err));
    isInFlight = false;
  }

  // show a message and redirect away after login.
  function handleSuccess() {
    showAlert({
      message: translate("support.login_success"),
      type: "success",
      autodismiss_ms: 2500,
    });

    if ($route0 === "login") {
      window.history.replaceState(null, null, "#/");
      refreshRoute();
    }
  }

  // Scroll Success and Error messages into view if they appear.
  let messageEl = false;
  $: if (messageEl) {
    messageEl.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
</script>

<form on:submit|preventDefault={handleSubmit} novalidate>
  <fieldset disabled={isInFlight}>
    <!-- Email -->
    <div class="form-group">
      <label for="InputEmail">{translate("form_labels.email")}</label>
      <input
        type="email"
        class="form-control"
        id="InputEmail"
        placeholder={translate("form_placeholders.email")}
        bind:value={email}
        required
      />
      <div class="invalid-feedback">
        {translate("validation.email_required")}
      </div>
    </div>

    <!-- Password -->
    <div class="form-group">
      <label for="InputPwd">{translate("form_labels.password")}</label>
      <input
        type="password"
        class="form-control"
        id="InputPwd"
        placeholder={translate("form_placeholders.password")}
        bind:value={password}
        required
      />
      <div class="invalid-feedback">
        {translate("validation.password_required")}
      </div>
    </div>

    <!-- Disclaimer -->
    <div class="text-left mt-4 mb-2">
      <p class="small" data-test="disclaimer">
        {translate("about.non_diagnostic_disclaimer")}
      </p>
    </div>

    <!-- Submission and Buttons -->
    <div>
      <button type="submit" id="SubmitLogin" class="btn btn-primary btn-block">
        {translate("form_labels.log_in")}
      </button>
    </div>
  </fieldset>

  <!-- Error Message -->
  {#if errorMessage}
    <div class="py-4" bind:this={messageEl}>
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">{translate("support.error_has_occurred")}</h4>
        <p>
          {@html errorMessage}
        </p>
      </div>
    </div>
  {/if}
</form>
