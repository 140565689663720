// Include our external dependencies.
import { ready as i18nReady, translate } from "i18n"; //eslint-disable-line import/no-unresolved
import jQuery from "jquery";
import { showAlert } from "./stores/alerts";
import * as Fathom from "fathom-client";
import { route0 } from "./stores/router";
import UI from "./ui.svelte";

// Render the application into the DOM (wait for translations to load).
i18nReady.then(function startApp() {
  //
  // Render the application.
  const uiElem = document.getElementById("UI");
  const ui = new UI({ target: uiElem });

  // Remove the Splash Overlay.
  document.getElementById("Splash").className = "hidden";

  // Feature Detection - WebGL
  if (!("WebGLRenderingContext" in window))
    showAlert({
      type: "danger",
      message: translate("no_webgl"),
    });

  // Apply Bootstrap CSS when a form is submitted and invalid.
  const jApp = jQuery(uiElem);
  jApp.on("submit", "form[novalidate]", function applyValidationCSSClass() {
    if (!this.checkValidity()) jQuery(this).addClass("was-validated");
  });

  // Scroll to top of the app, upon navigation.
  route0.subscribe(() => uiElem.scrollIntoView());

  // Setup analytics.
  if (process.env.FATHOM_SITE_ID) {
    Fathom.load(process.env.FATHOM_SITE_ID, {
      url: "https://fish.hyperfine.io/script.js",
      auto: false,
      excludedDomains: ["localhost"],
    });
    route0.subscribe(($route0) => {
      Fathom.trackPageview({ url: `/${$route0}` });
    });
  }

  return ui;
});
