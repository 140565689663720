// Include our external dependencies.
import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
import once from "lodash-es/once";
import { derived, writable } from "svelte/store";
import offlineStorage from "store/dist/store.modern.min";
import user, { onTenMinutesOfInactivity } from "../stores/user";
import { showAlert } from "../stores/alerts";
import { route0 } from "../stores/router";

let $route0;
route0.subscribe((r) => ($route0 = r));

// Scoped variables.
let authToken = "";
let token_expiration_timer = null;

// This "fetch" abstraction returns errors as strings.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export async function api(
  url,
  params = {},
  external = false,
  returnRaw = false
) {
  //
  // Inject the Authorization header.
  if (!external && authToken) {
    params.headers = {
      Authorization: `Bearer ${authToken}`,
      Accept: "application/json",
      ...params.headers,
    };
  }

  // Execute the HTTP request.
  const prefix = external ? "" : process.env.DICOMWEB_URL;
  const resp = await fetch(`${prefix}${url}`, params);

  // Handle 3xx, 4xx, and 5xx error codes.
  if (!resp.ok) {
    // Find the error message in a CPA Error, Text Body, or HTTP Status.
    let body;
    let error_message = "";
    await resp
      .text()
      .then((txtBody) => {
        error_message = txtBody;
        body = JSON.parse(txtBody);
        error_message = body.message;
      })
      .catch(() => {
        if (!error_message) error_message = resp.status;
      });

    // Handle redirects for expired/missing auth.
    if (resp.status === 401) {
      // If we're already on the login page, just show the message.
      if (!$route0 || !$route0 === "login") {
        throw error_message;
      } else if (body && body.password_failure_reason === "expired") {
        // Their password is expired! Redirect to Account Manager!
        showAlert({
          message: translate("auth.pass_is_expired"),
          type: "warning",
          dismissable: false,
          buttons: [
            `<a class="btn btn-sm btn-warning ml-3 text-truncate" href="${
              process.env.AM_URL
            }/login/expired" target="_blank">
              ${translate("auth.update_now")}
            </a>`,
          ],
        });
      } else {
        // Their session ended! Redirect to login!
        api.clearSession({ doReload: false });
        showAlert({
          message: translate("auth.sess_is_expired"),
          type: "warning",
        });
      }
      return {};
    }

    throw error_message;
  }

  // If requested, return the raw response.
  if (returnRaw) return resp;

  // Return JSON, text, or true
  return resp
    .json()
    .catch(() => resp.text())
    .catch(() => true);
}

// Shortcuts for HTTP methods.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
api.get = (url, params = {}) => api(url, { ...params, method: "GET" });
api.post = (url, body, method = "POST") =>
  api(url, {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
api.put = (url, body) => api.post(url, body, "PUT");
api.patch = (url, body) => api.post(url, body, "PATCH");
api.delete = (url) => api(url, { method: "DELETE" });

// Additional Custom Functions
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
let clearInactivityTimer = () => {};
api.setAuthToken = (newToken) => {
  //
  // Cache the new token in memory and localstorage.
  authToken = newToken;
  offlineStorage.set("cpa_tkn", newToken);

  // Decode the token to read the expiration.
  const { exp } = JSON.parse(atob(newToken.split(".")[1]));
  const exp_date = new Date(exp * 1000);
  clearTimeout(token_expiration_timer);

  // Check the session expiration and set a timer.
  const expires_in = exp_date - new Date() - 1;
  if (expires_in <= 0) return api.clearSession();
  token_expiration_timer = setTimeout(api.clearSession, expires_in);

  // Force re-auth after 10 minutes of inactivity
  clearInactivityTimer();
  clearInactivityTimer = onTenMinutesOfInactivity(() => {
    showAlert({
      type: "warning",
      message: translate(
        "was_inactive",
        Math.round(
          parseInt(process.env.SESSION_INACTIVITY_TIMEOUT_MS, 10) / 600
        ) / 100
      ),
    });
    api.clearSession({ doReload: false });
  });
};

api.authenticate = async ({ email, password }) => {
  //
  // Make the HTTP request to log into CPA.
  const resp = await api(
    `${process.env.CPA_URL}/api/v2/user_tokens`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    },
    true
  );

  // Save the User and Auth token.
  user.set(resp.user);
  api.setAuthToken(resp.access_token);
};

api.clearSession = (options) => {
  clearInactivityTimer();
  offlineStorage.remove("cpa_tkn");
  authToken = null;
  user.set(null);
  offlineStorage.remove("lastActivityAt");
  if (!options || options.doReload !== false) window.location.reload();
};

// Organization ID parameters.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const SelectedOrg = writable(offlineStorage.get("SelectedOrg") || null);
SelectedOrg.subscribe(($selectedOrg) =>
  offlineStorage.set("SelectedOrg", $selectedOrg)
);

export const OrgQP = derived([user, SelectedOrg], ([$user, $selectedOrg]) => {
  // Reject non-users
  if (!$user) return "";

  // Default to the first org in their personal collection.
  let orgId = Object.keys($user.app_metadata.organization_attributes)[0];

  // Allow hyperadmins to overload their org.
  if ($user.app_metadata.is_hyperfine_admin) orgId = $selectedOrg;

  return orgId ? `00200010=${orgId}` : "";
});

export const loadOrgs = once(async function OneTimeOrgLoad() {
  return api(
    `${process.env.CPA_URL}/api/v2/organizations`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
      },
    },
    true
  );
});

// Constants.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
api.supportHref = encodeURI(
  "mailto:cloud-support@hyperfine-research.com?subject=Viewer Help"
);

// Initialize the session from localstorage.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
const startup_token = offlineStorage.get("cpa_tkn");
if (startup_token) api.setAuthToken(startup_token);
