<script>
  // Import external dependencies.
  import modalpowers from "../helpers/action-modal";

  // Component Props
  export let series;
  export let uid = "";
</script>

<main
  class="modal fade"
  id="DicomDetailsModal{uid}"
  tabindex="-1"
  aria-hidden="true"
  aria-labelledby="DicomDetailsModalLabel{uid}"
  data-component="DicomDetailsModal"
  use:modalpowers
>
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="DicomDetailsModalLabel{uid}">
          DICOM Details
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if series}
          {@html series.toString()}
        {/if}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</main>
