<script>
  // Import our external dependencies.
  import { createEventDispatcher } from "svelte";
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import makeUUID from "../helpers/uuid";
  import debounce from "lodash-es/debounce";
  import { showAlert } from "../stores/alerts";

  // External Props
  export let className = "";
  export let placeholder = translate("form_labels.search_placeholder");
  export let searchTxt = "";
  export let debounceTime = 500;
  export let helpCopy = "";
  export let handleValidate;
  export let invalidTxt = "";

  // Internal Props
  const dispatch = createEventDispatcher();
  const uuid = makeUUID();

  // Search change handling with optional debounce.
  function handleSearchTxtChange({ target: { value } }) {
    if (value !== searchTxt) dispatch("searchTxtChange", value);
  }
  const debounced_handleSearchTxtChange = debounce(
    handleSearchTxtChange,
    debounceTime
  );

  // Help Info
  function handleInfoClick() {
    showAlert({
      type: "info",
      message: helpCopy,
      id: "SearchHelpAlert",
    });
  }

  // Validation Tracking
  let isValid = true;
  function _handleValidation({ target: { value } }) {
    if (!handleValidate) return;
    isValid = handleValidate(value);
  }
</script>

<!-- Search Controls -->

<div
  class="d-flex justify-content-between {className}"
  data-component="search-bar"
>
  <div class="input-group">
    <label class="input-group-prepend mb-0" for="SearchBar{uuid}">
      <span class="input-group-text d-block" id="seach-addon{uuid}">
        <i class="fas fa-search" />
      </span>
    </label>
    <input
      type="text"
      id="SearchBar{uuid}"
      class="form-control"
      class:is-invalid={!isValid}
      {placeholder}
      aria-label={placeholder}
      aria-describedby="seach-addon{uuid}"
      value={searchTxt}
      on:keyup={_handleValidation}
      on:keyup={debounceTime
        ? debounced_handleSearchTxtChange
        : handleSearchTxtChange}
      on:focus
    />

    <!-- Help / Info -->
    {#if helpCopy}
      <div class="input-group-append">
        <button
          class="btn btn-outline-light border border-left-0"
          type="button"
          on:click={handleInfoClick}
          data-test="SearchHelpBtn"
        >
          <i class="far fa-question-circle" />
        </button>
      </div>
    {/if}
  </div>
</div>
{#if invalidTxt}
  <div class="invalid-feedback pl-5 pr-3 mb--2" class:d-block={!isValid}>
    {invalidTxt}
  </div>
{/if}

<style type="text/scss">
  @import "bootstrap/variables";
  .input-group-append .btn {
    background-color: $input-bg;
    &:hover {
      background-color: $light;
    }
  }
</style>
