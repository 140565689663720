<svelte:options immutable={true} />

<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { SelectedOrg, loadOrgs } from "../helpers/api";
</script>

{#await loadOrgs()}
  <div class="animated fadeIn w-0">
    <div class="spinner-border small ml-3" role="status">
      <span class="sr-only">{translate("loadingDotDotDot")}</span>
    </div>
  </div>
{:then orgs}
  <select
    class="custom-select border-primary"
    title="Filter to a specific Organization!"
    bind:value={$SelectedOrg}
  >
    <option value={null}>All Scans</option>
    {#each orgs as { organization_id, organization_name } (organization_id)}
      <option value={organization_id}>{organization_name}</option>
    {/each}
  </select>
{/await}
