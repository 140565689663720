<script>
  // Import our external dependencies.
  import { fade } from "svelte/transition";
  import { route0, route1 } from "./stores/router";
  import Header from "./components/global-header.svelte";
  import About from "./components/about.svelte";
  import FourOhFour from "./components/four-oh-four.svelte";
  import Preauth from "./preauth/preauth.svelte";
  import Multiviewer from "./viewer-module/multiviewer.svelte";
  import user from "./stores/user";
  import { alerts, dismissAlert } from "./stores/alerts";
  import StudyHome from "./study-module/study-home.svelte";
  import StudyDetails from "./study-module/study-details.svelte";

  const env = process.env.NODE_ENV;
  const version = process.env.VERSION;
</script>

<main data-component="app" class="main w-100 min-vh-100 position-relative">
  <!-- User Login & Account Creation -->
  {#if !$user || $route0 === "login"}
    <Preauth />

    <!-- Application -->
  {:else}
    <div class="core-routing | h-100">
      <!-- Main View Routing -->
      {#if !$route0 || $route0 === "studies"}
        <StudyHome />
      {:else if $route0 === "study-details"}
        <StudyDetails studyId={$route1} />
      {:else if $route0 === "multiviewer"}
        <Multiviewer />
      {:else if $route0 === "about"}
        <About />
      {:else}
        <FourOhFour />
      {/if}
    </div>
    <Header className="position-absolute top-0 w-100" />
  {/if}

  <!-- Global Alerts -->
  <div class="alerts position-fixed bottom-0 d-flex flex-column px-2">
    {#each $alerts as alert}
      <div
        id={alert.id || ""}
        class="alert alert-{alert.type ||
          'danger'} d-flex show mx-auto |
        animated fadeIn overflow-hidden"
        class:alert-dismissable={alert.dismissable !== false}
        role="alert"
        out:fade={{ duration: 200 }}
      >
        <div class="d-flex">
          <div>
            {@html alert.message}
          </div>
          {#if alert.buttons}
            <div>
              {#each alert.buttons as button}
                {@html button}
              {/each}
            </div>
          {/if}
        </div>

        <!-- Auto-dismiss progress indicator. -->
        {#if alert.autodismiss_ms}
          <div
            class="auto-dismiss-progress-bar position-absolute bottom-0 left-0
            w-100 bg-{alert.type ||
              'danger'}"
            style="animation-duration: {alert.autodismiss_ms}ms;"
          />
        {/if}

        <!-- Close Button -->
        {#if alert.dismissable !== false}
          <button
            type="button"
            class="close pl-3 d-block mt--1"
            aria-label="Close"
            on:click={() => dismissAlert(alert)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        {/if}
      </div>
    {/each}
  </div>

  <!-- Global Version Number (not in prod) -->
  {#if env !== "production"}
    <div class="position-fixed bottom-0 right-0 text-white small pr-1">
      <small>{env || ""}-{version || ""}</small>
    </div>
  {/if}
</main>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";

  .main {
    overflow: auto;
  }
  .alerts {
    z-index: $zindex-popover;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .alert {
    max-width: 600px;
  }
  .auto-dismiss-progress-bar {
    height: 0.1875rem;
    animation-name: autoDismissProgress;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  @keyframes autoDismissProgress {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(100%, 0, 0);
    }
  }
</style>
